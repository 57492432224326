.Project-Booking {
    width: 100%;
    height: 100%;

    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
}

.project-Booking-Form {
    width: 90%;
    padding-left: 10%;
    height: auto;
    overflow-y: auto;
    padding-bottom: 100px;
}

.Project-Booking-Instructions {
    margin-top: 50px;
}

.Icon-Cancel {
    color: red;
}

.Icon-Approve {
    color: green;
}

.Project-Booking-Section-Title {
    width: 80%;
    font-size: 1.5em;
    margin-bottom: 10px;
    margin-top: 20px;
    margin-left: -20px;
}

.Project-Booking-Input-Form {
    margin-top: 20px;
}

.Project-Booking-Input-Field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    margin-right: 5px;
}

.Project-Booking-Input-Field-Label {
    margin-right: 10px;
    margin-bottom: 2px;
}

.Project-Booking-Input-Field-Input {
    border: none;
    border-bottom: 1px solid gray;
    height: 100%;
}

.Project-Booking-Customer-Search-Button {
    background-color: rgb(190, 190, 190);
    color: gray;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.Search-Button-Active {
    background-color: rgb(6, 89, 79);
    color: white;
    cursor: pointer;
}

.Project-Booking-Customer-Empty {
    margin-top: 20px;
}

.Project-Booking-Customer-Empty-Message {
    color: rgb(68, 68, 68);
    margin-top: 5px;
}

.Project-Booking-Customer-Link-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Project-Booking-Customer-Link-Text {
    background-color: rgb(245, 245, 245);
    height: 30px;
    padding-top: 2px;
}

.Project-Booking-Customer-Link-Copy {
    background-color: rgb(245, 245, 245);
    padding-left: 20px;
    cursor: pointer;
    width: 22px;
    height: 30px;
}

.Project-Booking-Customer-Link-Copy-Image {
    width: 15px;
    height: 15px;
    padding-top: 6px;
}

.Project-Booking-Customer-Error {
    color: red;
}

.Project-Booking-Customer-Clear-Button {
    background-color: rgb(240, 77, 77);
    color: rgb(207, 207, 207);
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 600;
}

.Project-Booking-Customer-Search-Message {
    color: gray;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.Project-Booking-Error {
    margin-top: 20px;
    margin-bottom: 50px;
}
.Project-Booking-Error-Line {
    font-size: 0.9em;
    color: red;
}

.Project-Booking-Customer-Selection {
    width: 90%;
    min-height: 200px;

    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-wrap: wrap;
    border: 1px solid gray;
    align-content: flex-start;
}

.Project-Booking-Customer-Selection-Header {
    display: flex;
    flex-direction: row;

    background-color: rgb(215, 215, 215);
    border: 1px solid black;
}

.Project-Booking-Customer-Selection-Header-Column {
    border-right: 1px solid black;
    margin-left: 2px;
}

.Project-Booking-Customer-Selection-Column {
    margin-left: 2px;
}

.Project-Booking-Customer-Selection-Row {
    display: flex;
    flex-direction: row;
}

.Project-Booking-Customer-Selection-Row:hover {
    background-color: rgb(236, 236, 236);
    cursor: pointer;
}

.Project-Booking-Selected-Customer-Window {

}

.Project-Booking-Selected-Customer-Location-Table {
    width: 90%;
    min-height: 100px;

    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-wrap: wrap;
    align-content: flex-start;
}

.Project-Booking-Selected-Customer-Locations-Title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.Project-Booking-Selected-Customer-Location {
    margin-bottom: 5px;
}

.Project-Booking-Selected-Customer-Location:hover {
    background-color: rgb(236, 236, 236);
    cursor: pointer;
}

.Project-Booking-Selected-Customer-Line {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Project-Booking-Selected-Customer-Line-Title {
    margin-right: 5px;
    color: rgb(180, 180, 180);
}

.Project-Booking-Submit {
    margin-top: 50px;
    margin-bottom: 50px;
}

.Project-Booking-Submit-Button {
    background-color: rgb(6, 89, 79);
    color: white;
    padding: 3px;
    font-size: 1.1em;
    cursor: pointer;
}

.Project-Booking-File-Insert {
    margin-top: 25px;
}

.Project-Booking-File-Update {
    margin-top: 5px;
    color: rgb(6, 89, 79);
    font-weight: 600;
}

.Project-Booking-Skip-Budget {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Project-Booking-Skip-Budget-Input {
    width: 100%;
    height: 100px;
    resize: none;
}

.Project-Booking-Skip-Budget-Cancel {
    margin-top: 5px;
}

.Project-Booking-Skip-Budget-Cancel:hover {
    cursor: pointer;
}

.Project-Booking-Service-Contract-Form {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Project-Booking-Service-Contract-Component {
    width: 400px;
    margin: 10px;
    padding: 10px;
    border: 1px solid gray;
}

.Project-Booking-Service-Contract-Add {
    background-color: rgb(6, 89, 79);
    color: white;
}

.Project-Booking-Service-Contract-Add:hover {
    cursor: pointer;
    filter: brightness(120%);
}

.Project-Booking-Service-Contract-Remove {
    background-color: rgb(251, 86, 86);
    color: white;
    margin-left: 10px;
}

.Project-Booking-Service-Contract-Remove:hover {
    cursor: pointer;
    filter: brightness(120%);
}

.Project-Booking-Submission-Window {
    margin-top: 50px;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 100px;
}

.project-Booking-Submitting-Header {
    font-size: 2em;
    margin-bottom: 50px;
    text-decoration: underline;
    text-align: center;
}

.Project-Booking-Submission-Section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Project-Booking-Submission-File-Group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Project-Booking-Accepted {
    width: 40px;
    height: 40px;
    color: rgb(6, 89, 79);
    margin-right: 15px;
}

.Project-Booking-Error {
    width: 40px;
    height: 40px;
    color: red;
    margin-right: 15px;
}

.Project-Booking-Submission-Title {
    font-size: 1.8em;
}

.Project-Booking-Submission-Seperator {
    width: 10px;
    height: 10px;
    color: gray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Project-Booking-Submission-Success {
    margin-top: 50px;
    font-size: 1.8em;
    text-align: center;
}

.Project-Booking-Rejection-Header {
    margin-top: 50px;
    width: 90%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.Project-Booking-Rejection-Header-Text {
    background-color: rgb(6, 89, 79);
    color: white;
    font-size: 1.3em;
    padding: 7px;
    padding-right: 15px;
    border-radius: 5px;
    margin-right: -20px;
}

.Project-Booking-Rejection-Header-Text:hover {
    cursor: pointer;
}

.Project-Booking-Rejection-Header-Icon {
    width: 80px;
    height: 80px;
    color: rgb(6, 89, 79);
}

.Project-Booking-Rejection-Header-Icon:hover {
    cursor: pointer;
}

.Project-Booking-Rejection-List {
    width: 90%;
    border-radius: 10px;
    margin-top: 20px;

    display: flex;
    flex-direction: row-reverse;
}

.Project-Booking-Rejection-Component {
    margin-left: 5px;
    width: 180px;
    background-color: rgb(6, 89, 79);
    color: white;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Project-Booking-Rejection-Component:hover {
    cursor: pointer;
    filter: brightness(110%)
}

.Project-Booking-Rejection-Line {
    margin-bottom: 20px;
}

.Project-Booking-Rejection-Title {
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.5);
}

.Project-Booking-Rejection-Text {
    color: white;
}

.Project-Booking-Rejection-Notes-Header {
    font-size: 1.2em;
    margin-top: 20px;
}

.Project-Booking-Rejection-Notes {
    width: 80%;
    height: 150px;
    
    overflow-y: auto;
    font-size: 1.1em;
    padding: 15px;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: rgba(255, 255, 0, 0.15);
}

.Project-Booking-Budget-Window {
    width: 80%;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 10px;
}

.Project-Booking-Budget-Header {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgb(214, 214, 214);
    font-weight: 600;
}

.Project-Booking-Budget-Line {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.Project-Booking-Budget-Files {
    display: flex;
    flex-direction: column;
}

.Project-Booking-Budget-Files-Tray{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.Project-Booking-Budget-File-Upload {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.Project-Booking-Budget-File-Title {
    margin-right: 5px;
    font-weight: 500;
}

.Project-Booking-Service-Budget {
    width: 800px;
    margin: 10px;
    padding: 10px;
    border: 1px solid gray;
}

.Project-Booking-Service-Budget-Error {
    color: red;
    width: 100%;
}

.Project-Booking-Budget-Input-Field {
    display: flex;
    flex-direction: row;
    justify-items: center;
    margin-bottom: 5px;
}

.Project-Booking-Budget-Input-Field-Label {
    font-weight: 600;
    margin-right: 10px;
}