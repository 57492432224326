.Logout-Window {
    width: 100%;
    height: 100%;



    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Logout-Frame {
}

.Logout-Title {
    font-size: 20px;
}

.Logout-Button-Group {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Logout-Button-Confirm {
    margin-top: 10px;
    background-color: rgba(255, 78, 78, 0.9);
    color: white;
    padding: 10px;
    width: auto;
    text-align: center;
    cursor: pointer;
    margin-right: 40px;
    border-radius: 5px;
}

.Logout-Button-Cancel {
    margin-top: 10px;
    background-color: rgba(92, 92, 92, 0.9);
    color: white;
    padding: 10px;
    width: auto;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}