.Account-Settings-Window {
    width: 100%;
    height: 100%;
}

.Account-Settings-Section{
    width: 80%;
    margin: auto;
    margin-top: 40px;
}

.Account-Settings-Title {
    color: gray;
    font-size: 14px;
    font-weight: 600;
}

.Account-Settings-Value {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 18px;
}

.Account-Settings-Priv-Group {
    margin-top: 20px;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
}

.Account-Settings-Priv-Icon {
    width: 20px;
    height: 20px;
}

.Account-Settings-Priv-Selected {
    color: green;
}

.Account-Settings-Priv-Title {
    margin-left: 10px;
    font-size: 18px;
    justify-self: center;
    margin-top: -3px;
}

.Account-Settings-Update-Password {
    margin-top: 10px;
    background-color: rgba(6, 89, 79, 0.9);
    color: white;
    padding: 5px;
    width: auto;
    text-align: center;
    width: 220px;
    cursor: pointer;
    border-radius: 5px;
}

.Account-Settings-Confirm {
    margin-top: 10px;
    background-color: rgba(6, 89, 79, 0.3);
    color: white;
    padding: 5px;
    width: auto;
    text-align: center;
    width: 220px;
    border-radius: 5px;
}

.Account-Settings-Confirm-Clickable {
    margin-top: 10px;
    background-color: rgba(6, 89, 79, 0.9);
    color: white;
    padding: 5px;
    width: auto;
    text-align: center;
    width: 220px;
    cursor: pointer;
    border-radius: 5px;
}

.Account-Settings-Warning {
    color: red;
    font-size: 14px;
}

.Account-Settings-Success {
    color: green;
    font-size: 14px;
}

.Account-Settings-Cancel {
    margin-top: 10px;
    background-color: rgba(255, 78, 78, 0.9);
    color: white;
    padding: 5px;
    width: auto;
    text-align: center;
    width: 220px;
    cursor: pointer;
    border-radius: 5px;
}