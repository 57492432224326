.Desktop-Navbar {
    width:90%;
    height:95%;
    margin: auto;
    margin-top: var(--navMargin);
}

.Nav-Logo {
    height: var(--navLogoHeight);
    width: 100%;
    margin-bottom: var(--navMargin);
}

.Nav-Symbol {
    width: 100%;
}

.Nav-Expander {
    height: var(--expanderHeight);
    width: var(--expanderHeight);
    margin-right: 10px;
    margin-left: auto;
}

.Nav-Control-Symbol {
    color: white;
    height: var(--expanderHeight);
    width: var(--expanderHeight);
    cursor: pointer;
}

.Mobile-Navbar {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    height: 100%;
    padding-left: 15px;
    padding-right: 25px;
}

.Mobile-Nav-Options {
    width: 50px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Mobile-Navbar-Bars {
    color: white;
    height: 30px;
    width: 30px;
    align-self: center;
    cursor: pointer;
}

.Mobile-Nav-Logo {
    width: 180px;
    height: 100%;
}

.Mobile-Nav-Logo-Image {
    width: 100%;
    height: 100%;
}

.Mobile-Nav-User {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Mobile-Nav-User-Bubble {
    background-color: white;
    height: 38px;
    width: 38px;
    border-radius: 50%;
    border: 2px solid rgb(75, 75, 75);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Mobile-Nav-User-Inner-Bubble {
    font-size: 24px;
    font-weight: 600;
    color: rgb(75, 75, 75);
}