.Mobile-Display-Window {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Desktop-Display-Window {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.Mobile-Nav {
    width: 100%;
    height: 68px;
    background-color: rgb(6, 89, 79);
}

.Desktop-Nav {
    width: var(--navWidth);
    height: 100%;
    background-color: rgb(6, 89, 79);
}

.Mobile-Primary {
    width: 100%;
    height: var(--primaryHeight);
}


.Desktop-Primary {
    width: var(--primaryWidth);
    height: 100%;
}

.Mobile-Nav-Menu {
    position: absolute;
    top: var(--topBarHeight);
    width: var(--menuWidth);
    height: var(--menuHeight);
    margin-left: var(--menuMargin);
    background-color: rgb(6, 89, 79);
}

.Mobile-Nav-Menu-Inner {
    width: 90%;
    margin-left: 2%;
}