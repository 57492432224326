.Users-Window {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Users-Header {
    width: 100%;
    height: 28%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding-top: 30px;
}

.Users-Header-Search {
    width: 92%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

}

.Users-Header-Search-Bar {
    background: rgba(206, 206, 206, 0.2);
    width: 450px;
    padding: 6px;
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.Users-Header-Search-Icon {
    width: 20px;
    height: 20px;
    color: gray;
    margin-left: 5px;
}

.Users-Header-Search-Input {
    border: none;
    font-size: 1em;
    background-color: transparent;
    width: 100%;
    margin-left: 5px;
}

.Users-Header-Search-Input:focus{
    outline: none;
}

.Users-Header-Managed {
    width: 92%;
    display: flex;
    flex-direction: column;
}

.Users-Header-Top-Bar {
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    margin-bottom: 10px;
}

.Users-Header-Top-Bar-Text {
    width: 175px;
    border-bottom: 2px solid rgb(6, 89, 79);
    padding-bottom: 10px;
    font-size: .9em;
    font-weight: 500;
}

.Users-Header-Lower {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Users-Header-Lower-Left {
    width: 80%;
}

.Users-Header-Lower-Left-Count {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 5px;
}

.Users-Header-Lower-Left-Text {
    font-size: .8em;
}

.Users-Header-Button {
    background-color: rgb(6, 89, 79);
    color: white;
    padding: 7px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.Users-Header-Column-Headers {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 90%;
    color: rgb(128, 128, 128);
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    padding-bottom: 5px;
    font-size: 0.8em;
}

.Users-Display {
    width: 100%;
    height: 72%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Users-Display-Window {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
}

.Users-Display-Row {
    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(69, 69, 69, 0.9)
}

.Users-Display-Row-Border {
    width: 100%;
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    color: rgba(69, 69, 69, 0.9)
}

.Users-Display-Field {
    font-size: .9em;
}

.Field-Centered {
    text-align: center;
}

.Users-Display-Unchecked {
    width: 20px;
    height: 20px;
    color: gray;
}

.Users-Display-Checked{
    width: 20px;
    height: 20px;
    color: rgb(6, 89, 79);
}

.User-Input {
    font-size: .9em;
    border: none;
    border-bottom: 1px solid rgba(128, 128, 128, 0.4);
}

.Users-Display-Tools {
    margin-right: 25px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.Users-Display-Tools-Button {
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 5px;
    border: 1px solid rgba(128, 128, 128, 0.7);
    font-size: .9em;
    font-weight: 500;
    color: rgba(0, 0, 0, .7);
    margin-left: 5px;
    text-align: center;
}

.New-User-Display {
    position: absolute;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .8);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.New-User-Window {
    width: 600px;
    height: 400px;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.New-User-Header {
    width: 90%;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.New-User-Header-Title {
    font-weight: 500;
}

.New-User-Header-Close {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: rgba(128, 128, 128, 0.7);
}

.New-User-Instructions {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: .8em;
}

.New-User-Input-Window {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.New-User-Input-Text {
    width: 250px;
    margin-bottom: 20px;
}

.New-User-Input {
    width: 90%;
    border: none;
    border-bottom: 1px solid rgba(128, 128, 128, 0.7);
    padding: 5px;
}

.New-User-Input-Title {
    font-size: .9em;
    margin-bottom: 15px;
    margin-top: -3px;
}

.New-User-Privilege-Title {
    font-size: .9em;
    font-weight: 500;
    margin-top: 20px;
}

.New-User-Input-Select {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.New-User-Input-Selection {
    margin-right: 10px;
}

.New-User-Submit {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.New-User-Submit-Button {
    background-color: rgb(6, 89, 79);
    color: white;
    padding: 7px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.New-User-Submitting {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.New-User-Submitting-Symbol {
    width: 50px;
    height: 50px;
    color: rgb(6, 89, 79);
}

.New-User-Submitting-Text {
    margin-top: 10px;
    font-size: 1em;
    color: rgb(82, 82, 82);
}

.New-User-Failed-Symbol {
    width: 80px;
    height: 80px;
    color: red;
}

.New-User-Privileges {
    
}