.Set-Password-Window {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, #32584F 48%, white 10%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Set-Password-Box {
    filter: drop-shadow(1px 1px 4px #535353);
    border-radius: 10px;
    background-color: white;
    width: 400px;
    height: 600px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Password-Image-Text {
    width: 60%;
    padding-top: 20px;
}

.Password-Image {
    width: 40%;
}

.Password-Error-Text {
    margin-top: 20px;
    text-align: center;
    color: red;
}

.Password-Error-Symbol {
    color: red;
    width: 50px;
    height: 50px;
}

.Password-Input {
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid black;
}

.Password-Button {
    margin-top: 20px;
    background-color: #32584F;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

.Password-Submitting {
    margin-top: 40px;
    text-align: center;
}

.Password-Check {
    width: 50px;
    height: 50px;
    color: #32584F;
}

