.External-Form-Window {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgb(248,248,248);
    overflow-x: hidden;
    overflow-y: scroll;
}

.External-Form-Page {
    min-height: 100%;
    width: 100%;
    margin: 2px;
    max-width: 1100px;
    background-color: white;
    border: 2px solid rgb(203,203,203);
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.External-Form-Header {
    width: 90%;
    border-bottom: 4px solid rgb(6, 89, 79);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.1em;
}

.External-Form-Logo {
    width: 70%;
    max-width: 400px;
    margin-top: 40px;
}

.External-Form-Contact {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.External-Form-Contact-Content {
    margin-left: 20px;
    text-align: center;
}

.External-Vendor-Contact-Button {
    margin-top: 20px;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.Button-Add {
    background-color: rgb(6, 89, 79);
    color: white;
}

.Button-Remove {
    background-color: rgb(160, 73, 73);
    color: white;
}

.External-Form-Email-Support {
    margin-bottom: 10px;
    font-weight: 500;
}

.External-Vendor-Title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.1em;
}

.External-Vendor-Type-Title {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1.6em;
    text-align: center;
    width: 100%;
}

.External-Vendor-Type-Subtitle {
    margin-bottom: 15px;
    margin-top: -10px;
    font-size: 1.8em;
    text-align: center;
    width: 100%;
}

.External-Vendor-Type-Line-Title {
    margin-top: 15px;
    font-size: 1.6em;
    text-align: center;
    width: 100%;
    font-weight: 500;
}

.External-Vendor-Bold {
    font-weight: 700;
}

.External-Vendor-Italic {
    font-style: italic;
    font-size: 1.1em;
}

.External-Vendor-Type-Selections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1.2rem;
}

.External-Vendor-Type-Selection-Group {
    display: flex;
    flex-direction: row;
}

.External-Vendor-Type-Selection {
    cursor: pointer;
    height: 26px;
}

.External-Vendor-Type-Selection-Text {
    margin-left: 10px;
    margin-right: 20px;
}

.External-Form-Spacer {
    width: 100%;
}

.External-Vendor-Information {
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.External-Form-Input {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    margin-right: 10px;
    margin-top: 15px;
    align-items: center;
}

.External-Form-Input-Under {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-right: 10px;
    margin-top: 15px;
}

.External-Form-Input-Required {
    color: red;
    width: 8px;
    height: 8px;
}

.External-Form-Input-Complete {
    color: green;
    width: 12px;
    height: 12px;
}

.External-Vendor-Type-Error {
    width: 100%;
    text-align: center;
    font-size: .9em;
    color: red;
    margin-top: 5px;
}

.External-Form-Input-Not-Required {
    color: rgb(203, 203, 203);
    width: 8px;
    height: 8px;
}

.External-Form-Input-Title {
    margin-right: 5px;
    font-size: 1em;
    font-weight: 500;
    color: gray;
}

.External-Form-Input-Title-Under {
    font-size: 1em;
    text-align: center;
    color: gray;
}

.External-Form-Input-Box {
    width: var(--inputWidth);
    max-width: 50vw;
    font-family: monospace;
    font-size: 1.3em;
    border: none;
    border-bottom: 2px solid black;
}

.External-Form-Input-Contrained {  
    font-family: monospace;
    font-size: 1.3em;
    letter-spacing: 8px;
    border: none;
    border-bottom: 2px solid black;
    width: var(--inputWidth);
}

.External-Vendor-Contact-Group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


.External-Form-Dropdown {
    width: var(--inputWidth);
    max-width: 50vw;
    font-family: monospace;
    font-size: 1.3em;
    border: none;
    border-bottom: 2px solid black;
}

.External-Vendor-Selection-Section {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.External-Vendor-Selection-Title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.External-Vendor-Thick-Spacer {
    margin-top: 25px;
}

.External-Form-Conditions {
    width: 95%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.External-Form-Conditions-Text {
    font-size: 1.1em;
    text-align: center;
}

.External-Form-Conditions-Accept {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: center;
}

.External-Form-Conditions-Accept-Input {
    height: 15px;
    width: 15px;
}

.External-Form-Conditions-Accept-Text {
    font-size: 1.3em;
    margin-left: 10px;
}

.External-Form-Conditions-Information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.External-Form-Conditions-Date {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
}
.External-Vendor-Signature-Section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.External-Vendor-Signature-Text {
    font-size: .9em;
    font-style: italic;  
}

.External-Form-Signature-Box {
    width: var(--inputWidth);
    max-width: 50vw;
    font-family: monospace;
    font-size: 2em;
    border: none;
    border-bottom: 2px solid black;
    font-family: "Whisper", cursive;
}

.External-Form-Signature {
    border: 1px solid black;
    border-radius: 50px;
}

.External-Form-Conditions-Buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.External-Vendor-Button {
    cursor: pointer;
}

.External-Form-Signature-Clear {
    width: 120px;
}

.External-Vendor-Submit {
    margin-top: 20px;
    background-color: rgb(6, 89, 79);
    color: white;
    font-size: 1.4em;
    padding: 10px;
    border-radius: 5px;
}


.External-Form-Submission-Window {
    margin-top: 50px;
    width: auto;
}

.External-Form-Submission-Section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.External-Form-Submission-File-Group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.External-Form-Accepted {
    width: 40px;
    height: 40px;
    color: rgb(6, 89, 79);
    margin-right: 15px;
}

.External-Form-Submission-Title {
    font-size: 1.8em;
}

.External-Form-Submission-Seperator {
    width: 10px;
    height: 10px;
    color: gray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.External-Form-Submission-Success {
    margin-top: 50px;
    font-size: 1.8em;
}


.Rejection-Header {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 10px;
}

.Rejection-Header-Title {
    font-size: 1.3em;
    margin-bottom: 20px;
    font-weight: 700;
}

.Rejection-Header-Text {
    font-size: 1.1em;
    padding: 15px;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: rgba(255, 255, 0, 0.15);
}

.Rejection-Header-Instructions {
    margin-top: 15px;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 20px;
    text-decoration: underline;
}

.External-Vendor-SSL {
    margin-top: 50px;
}