

.Nav-Links {
    width: 100%;
    height: var(--navLinksHeight);
}

.Nav-Link-Box {
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.Nav-Link-Box-Selected {
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    cursor: pointer;
}

.Nav-Link-Symbol {
    color: white;
    width: 18px;
    height: 18px;
    margin-left: 15px;
}

.Nav-Link-Text {
    color: white;
    font-size: 14px;
    margin-left: 16px;
    font-weight: 600;
}