.Fixed-Assets {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Fixed-Assets-Temp {
    font-size: 1.2em;
}