.Conversion-Window {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.Conversion-Header {
    height: 100px;
    width: 80%;
    display: flex;
    align-items: flex-end;
}

.Conversion-Header-Title {
    font-size: 1.5em;
}

.Conversion-Selection {
    width: 70%;
    margin-top: 40px;
    margin-bottom: 30px;
}

.Conversion-Import {
    width: 70%;
}

.Conversion-Uploading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
}

.Conversion-Import-Upload {
    width: 100%;
}

.Conversion-Import-Submit {
    background-color: rgb(6, 89, 79);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    font-size: 1.2em;
    margin-top: 40px;
    width: 200px;
}

.Conversion-Import-Download {
    background-color: rgb(6, 89, 79);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    font-size: 1.2em;
    margin-top: 40px;
}

.Conversion-Download {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.Conversion-Download-Label {
    width: 100%;
    margin-bottom: 5px;
    font-size: 1.1em;
}

.Conversion-Error {
    display: flex;
    flex-direction: column;
    width: 60%;
    border: 1px solid gray;
    padding: 30px;
}

.Conversion-Error-Label {
    font-size: 1.4em;
    margin-bottom: 10px;
    text-decoration: underline;
}

.Conversion-Error-Line {
    color: red;
    margin-top: 2px;
}

.Conversion-Reset {
    width: 70%;
}

.Conversion-Reset-Button{
    background-color: rgb(196, 24, 1);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    font-size: 1em;
    margin-top: 20px;
}

.Conversion-Text-Section {
    margin-top: 50px;
    margin-top: 20px;
    width: 80%;
    margin-left: 40px;
}

.Conversion-Text-Line {
    font-weight: 600;
}

.Conversion-Import-Instructions {
    font-size: 1.1em;
    margin-bottom: 5px;
}